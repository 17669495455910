.app {
    text-align: center;
  }
  
  .title {
    margin-top: 25vh;
    font-size: 2em;
  }
  
  .upload-area {
    margin: 20px;
  }
  
  .dropzone {
  height: 100px;
  border: 2px dashed #000;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 10px;
}
  
  .transcribe-button {
    display: block;
    margin: 20px auto;
  }
  
  .status-messages {
    height: 100px;
    border: 1px solid black;
    margin: 20px;
    padding: 10px;
  }
  
  .download-button {
    display: block;
    margin: 20px auto;
  }
  
  .text-area {
    height: 200px;
    border: 1px solid black;
    margin: 20px;
    padding: 10px;
  }
  